.carsonalimg{
    height: 630px;
    width: 100%;
    object-fit: contain;
}
.carosalCont{
    height: 570px;
    width: 350px;
}

.Testimonialcont{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 80px;
    height: 100%;
}



.formCont{
    width:50%;
   padding: 60px;    
}

.subBtn{
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 20px;
}

@media screen and (max-width:786px) {
    .Testimonialcont{
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top:15%;
        height: 100%;
    }
    .carosalCont{
        height: 500px;
        width:100%;
        align-self: center; 
    }
    .carsonalimg{
        height: 500px;
        width: 100%;
        object-fit:contain;;
    }
    .formCont{
        width:95%;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin-left: 7px;
        padding: 0px;
        
    }
    .subBtn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        }
    
}

@media screen and (max-width:320px)
{
    .Testimonialcont{
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top:10%;
    }
}

@media  screen and (min-width: 1200px) {
    .subBtn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        }
}

