
 .contact{
    height:80vh;
    width:100%;
    display: flex;
    flex: 1;
 
    
 
}

.contact-div{
position: relative;
align-self: flex-end;
height: 300px;
width:100%;
background-color: whitesmoke;
display: flex;
flex-direction: row;



}

.contact-cont-1{
  width:500px;
  margin-top: 60px;
  margin-left: 70px;

  

}


.contact-cont-2{
    position: absolute;
    width:350px;
    background-color: whitesmoke;
    text-align: center;
    height: 350px;
    left: 60%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    bottom:60px;
    color: azure;
    
}


.contact-cont-3{    position: absolute;

    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    top:30%;
    left: 97%;
   
}   


.call-us{
    margin-top: 40px;
    font-size: 40px;
   
}
.number{
 
   font-size: 16px;
   text-decoration: none;
   margin-top: 5px;
   color: black;
}



.location{
    position: relative;
    font-size: 40px;
    bottom: 35px
    
}
.text-location{
    font-size: 18px;
    position: relative;
    bottom: 43px; 
    text-align:center;
    margin-bottom: 10px;
}
.Business{
    position: relative;
    bottom: 40px
}
.text-location-1{
    font-size: 16px;
    position: relative;
    bottom: 45px;
}
.contact-1{
    text-align: center;
    position: relative;

}
.contact-2{
    text-align: center;
    position: relative;
    top: 50px;
}

.underline-hover-effect {
    display: inline-block;
    padding-bottom: 0.25rem; /* defines the space between text and underline */
    position: relative;
  }
  
  .underline-hover-effect::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #1e90ff;
    transition: width 0.25s ease-out;
  }
  .underline-hover-effect:hover::before {
    width: 100%;
  }
.phone_numbers{

  display: flex;
  flex-direction: column;
  justify-content: center;
}
  .email{
    color:black; 
    text-decoration:none
  }

/* ipad pro */
@media screen and (max-width:1024px){
    .contact{
        position: relative;
        height:100%;
        width:100%;
        display: flex;
        flex: 0;
        padding-top:3%;
        background-color: whitesmoke;
        margin-top: 0;
    }
    .contact-div{
        align-self: center;
        height: 500px;
        width:100%;
        display: flex;
        flex-direction: column;   
        background-color: whitesmoke;
        
        }
        .contact-cont-1{
            width:100%;
            height: 100%;
            margin-top: 0;
            margin-left: 0;      
            text-align: center;   
            background-color: whitesmoke;
          }
          
        .contact-cont-2{
        position: relative;
         width:100%;
         height: 500px;
         text-align: center;
         left:0;
         box-shadow:none;
         background-color: whitesmoke;
         bottom:0;
         color:black;
    }
    .contact-cont-3{   
         position: relative;
        height: 100%;
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        top:0;
        left: 0;
        padding: 10px;
       
        background-color: whitesmoke;
    } 

}

/* ipad */
@media screen and (max-width:768px) { 

    .contact{
        position: relative;
        height: 95vh;
        width:100%;
        display: flex;
        flex: 0;
        padding-top:0;
        background-color: whitesmoke;
        margin-top: 0;
    }
    .contact-div{
        align-self: center;
        height: 500px;
        width:100%;
        display: flex;
        flex-direction: column;   
        background-color: whitesmoke;
        
        
        }
        .contact-cont-1{
            width:100%;
            height: 100%;
            margin-top: 0;
            margin-left: 0;      
            text-align: center;   
            background-color: whitesmoke;
            
          }
          
        .contact-cont-2{
        position: relative;
         width:100%;
         height: 500px;
         text-align: center;
         left:0;
         box-shadow:none;
         background-color: whitesmoke;
         bottom:0;
         color:black;
    }
    .contact-cont-3{   
         position: relative;
        height: 100%;
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        top:0;
        left: 0;
        padding: 10px;
       
        background-color: whitesmoke;
    } 

          
}

/* phone */
@media screen and (max-width:400px) { 
    .contact{
        position: relative;
        height: 100vh;
        width:100%;
        display: flex;
        flex: 0;
        padding-top: 30%;
        background-color: whitesmoke;
        color:black;
    }
    .contact-cont-2{
        position: relative;
         width:100%;
         height: 500px;
         text-align: center;
         left:0;
         box-shadow:none;
         background-color: whitesmoke;
         bottom:0;
         color:black;
    }
    .contact-cont-3{    position: relative;

        height: 100%;
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        top:0;
        left: 0;
        padding: 10px;
        margin-top: 0;
        background-color: whitesmoke;
        
    } 
 
}