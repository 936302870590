*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Gill Sans', 'Gill Sans MT',  'Trebuchet MS', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  
}
/* 
.app{
  background-image: url("./assets/background.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  background-color:white;
} */

/* .myVideo {
  position:fixed;
  height:100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
  
} */

.myVideo{
  position: fixed;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}