.demo{
  height: 100%;
  width: 100%;
  padding-top:5%;
}
.our-team{
    text-align: center;
    position: relative;
}
.about-cont-1,.about-cont-2{
  height: 89vh;
}
.our-team .pic{
    width: 280px;
    height:280px;
    border-radius: 50%;
    /* border: 8px solid #7a4b94; */
    margin: 0 auto;
    overflow: hidden;
}
.our-team img{
    width: 100%;
    height: auto;
}
.our-team .social{
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 50px;
    left: 20px;
    transition: all 0.3s ease-out 0s;
}
.our-team .social li a{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    background: #f28a31;
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-bottom: 5px;
    transform: scale(0);
    transition: all 0.2s ease 0s;
}
.our-team:hover .social li a{ transform: scale(1); }
.our-team:hover .social li a:hover{ background: #7a4b94; }
.our-team .team-content{
    padding: 15px 15px 20px;
    /* background: #7a4b94; */
    border-radius: 40px 10px;
    margin-top: -20px;
    color: rgb(0, 0, 0);
}
.our-team .title{
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
}
.our-team .post{
    display: block;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
}
.our-team .description{
    padding: 20px;
    background: #fff;
    border-radius: 20px;
    font-size: 16px;
    color: #666;
    line-height: 30px;
    margin-bottom: 0;
}

.AboutContent{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  height: 100%;
  margin-bottom: 5%;
}

.ContextLeftText{
  position: relative;
  font-size: 35px;
  top: 20px;
  right: 4px;
  line-height:1;
  background: -webkit-linear-gradient(100deg,#f05afe, #59f2f5 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  }

.ContexRightText{
  position: relative;
  width: 830px;
  margin-top: 27px;
  top: 15px;
  right: 20px;
  font-size: 17px;
  text-align: justify;
  line-height:22px;
  /* text-transform: lowercase; */
  }

.ContexRightTextdiv{
  margin-top: 8px;
}

.overlay {
 
  position: absolute;
  top: 0;
  bottom: 0;
  left: 13px;
  right: 0;
  height:280px;
  width: 280px;
  opacity: 0;
  transition: .4s ease;
  
}
.container1:hover .overlay {
opacity: 1;
}
.about-cont-2{
  padding-top: 50px;
}



@media screen and (max-width: 1397px){
  .demo{
    padding-top: 10%;
    height:100%;
 }
 .ContexRightTextdiv{
  margin-top: 10px;
}
 .AboutContent{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
    .our-team{ margin-bottom: 30px; }
    .ContextLeftText{
      font-size: 28px;
      background: -webkit-linear-gradient(15deg,#f05afe, #59f2f5 );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      margin-bottom:3px
      }
     .ContexRightText{
      width: 95%;
      margin-top: 15px;
      font-size: large;
      text-align: justify;
      word-spacing:1px;
    }
}

@media screen and (max-width:1280px)
{
  .demo{
    height: 100%;
    width: 100%;
    padding-top:100px;
    
  }
  .about-cont-1,.about-cont-2{
    height: 100%;
  }
  .ContexRightText{
    width: 100%;
    margin-top: 30px;
    font-size: large;
    text-align: justify;
    word-spacing:1px;
    right: 0px;
    padding:8px;
   
  }
  .overlay {
 
    position: absolute;
    top: 0;
    bottom: 0;
    left:24%;
    right: 0;
    height:280px;
    width: 280px;
    opacity: 0;
    transition: .4s ease;
    
  }

}

@media screen and (max-width:1024px)
{
  .demo{
    height: 100%;
    width: 100%;
    padding-top:100px;
    
  }
  .about-cont-1,.about-cont-2{
    height: 100%;
  }
  .ContexRightText{
    width: 100%;
    margin-top: 30px;
    font-size: large;
    text-align: justify;
    word-spacing:1px;
    right: 0px;
    padding:8px;
   
  }
  .overlay {
 
    position: absolute;
    top: 0;
    bottom: 0;
    left:19%;
    right: 0;
    height:280px;
    width: 280px;
    opacity: 0;
    transition: .4s ease;
    
  }

}


@media screen and (max-width:768px)
{
  .overlay {
 
    position: absolute;
    top: 0;
    bottom: 0;
    left:8%;
    right: 0;
    height:280px;
    width: 280px;
    opacity: 0;
    transition: .4s ease;
    
  }
}
@media screen and (max-width:600px)
{
  .overlay {
 
    position: absolute;
    top: 0;
    bottom: 0;
    left:14%;
    right: 0;
    height:280px;
    width: 280px;
    opacity: 0;
    transition: .4s ease;
    
  }
}

@media screen and (max-width:575px)
{
  .overlay {
    left:25%;   
  }
}

@media screen and (max-width:565px)
{
  .overlay {
    left:24%;   
  }
}

@media screen and (max-width:540px)
{
  .overlay {
    left:23%;   
  }
}

@media screen and (max-width:530px)
{
  .overlay {
 
    left:22%;

  }
}


@media screen and (max-width:500px)
{
  .overlay {

    left:14%;
     
  }
}

@media screen and (max-width:499px)
{
  .overlay {
    left:20%;   
  }
}

@media screen and (max-width:480px)
{
  .overlay {
    left:19%;   
  }
}

@media screen and (max-width:466px)
{
  .overlay {
    left:18%;   
  }
  
}

@media screen and (max-width:446px)
{
  .overlay {
    left:17%;   
  }
  
}

@media screen and (max-width:436px)
{
  .overlay {
    left:16%;   
  }
  
}

@media screen and (max-width:426px)
{
  .overlay {
    left:15%;   
  }
  
}

@media screen and (max-width:415px)
{
  .overlay {
    left:14%;   
  }
  
}

@media screen and (max-width:400px)
{
  .overlay {
    left:12.5%;   
  }
  
}



@media screen and (max-width:399px)
{
  .overlay {
    left:12.2%;
  }
}

@media screen and (max-width:395px)
{
  .overlay {
    left:12%;
    right: 0;
    
  }
}

@media screen and (max-width:385px)
{
  .overlay {
 
    left:11%;
    
  }
}

@media screen and (max-width:376px)
{
  .overlay {
 
    left:10%;
    
  }
}

@media screen and (max-width:366px)
{
  .overlay {
    left:9%;

    
  }
}


@media  screen and (max-width: 360px){
    .our-team .pic{
      height:250px;
      width: 250px;
    }
    .overlay {
 
      position: absolute;
      top: 0;
      bottom: 0;
      left:13%;
      right: 0;
      height:250px;
      width: 250px;
      opacity: 0;
      transition: .4s ease;
      
    }
    .our-team .social{ top: 20px; }
}

@media  screen and (max-width: 356px){
 
  .overlay {

    left:12%;
    
  }
}

@media  screen and (max-width: 345px){
 
  .overlay {

    left:11%;
    
  }
}

@media  screen and (max-width: 336px){
 
  .overlay {
    left:10%;
    
  }
}

@media screen and (max-width:326px)
{
  .overlay {
    left:9%;
    
  }
}

@media screen and (max-width:320px)
{
  .overlay {
 
    left:7.4%;
    
  }
}
@media screen and (max-width:311px)
{
  .overlay {
 
    left:6%;
    
  }
}

@media screen and (max-width:301px)
{
  .overlay {
    left:5%;

  }
}

@media screen and (max-width:291px)
{
  .overlay {
    left:1%;   
  }
}
