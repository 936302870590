.heroSection{
    height: 100%;
    width: 100%;
    padding-top:8%;
}
.heroSectioncont{
    width: 100%;
    height: 100%;
    padding: 8px;
    

}
.heroSection-logo-cont{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;

}
.heroSection-logo{
    height: 50px;
    width: 50px;
}



.heroSection-cont-header{
    font-size: 40px;
    font-weight: bolder;

    text-align: center;
    z-index:1;
}

.heroSection-text{
    text-align: center;
    font-size: 20px;
    bottom: 20px; 
    
}

  
  
  .heroimgcontdivtext {
    color: white;
    font-size: 16px;
    padding: 16px 32px;
    text-align: center;
  }

  .heroimgcont{
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    top: 40px;
    
  
}
.heroimgcont2{
  display: grid;
  grid-template-columns: auto   ;
  justify-content: center;

}
  .heroimgcontdiv {
    position: relative;
    width:300px;
  }
  
  .HeroSection-img {
    opacity: 1;
    display: block;
    width: 300px;
    height: 250px;
    object-fit: contain;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 250px;
    text-align: center;
  }
  
  .heroimgcontdiv:hover .image {
    opacity: 0.3;
  }
  
  .heroimgcontdiv:hover .middle {
    opacity: 1;
  }
  
  .text {

    color: rgb(0, 0, 0);
    font-size:16px;
    padding: 16px 32px;
    text-align: left;
  }
  
 @media (min-width: 640px) {     /* ipad  */
    .heroSection{
        height: 50%;
        width: 100%;
    }

    .heroimgcont{
        display: grid;
        grid-template-rows: auto auto ;
        justify-content: space-around;
    }
    .heroimgcont2{
      display: grid;
      grid-template-rows: auto auto  ;
      justify-content: space-around;
    }
    .heroimgcontdiv img{
        height: 300px;
        width: 300px;  
        transition: .5s ease;
        backface-visibility: hidden;
        opacity: 1;
        display: block;
    }
  }

  
  @media (min-width: 1280px) {   /* desktop */
    .heroSection{
        height: 100vh;
        width: 100%;
        padding-top:8%;
        overflow: hidden;     
    }

    .heroimgcont{
      position: relative;
        bottom: 8%;
        margin-top: 10px;
        display: grid;
        grid-template-columns: auto auto auto ;
        grid-column-gap: 2%;
        justify-content: center;
    }
    
    .heroimgcont2{
      position: relative;
      bottom: 25%;  
      left: 14px;
      display: grid;
      grid-template-columns: auto auto auto auto ;
      grid-column-gap: 2%;
      justify-content: center;  
 
      
    }

    .heroimgcontdiv {
        position: relative;
        width:300px;
      }
      
      .heroimgcontdiv img {
        opacity: 1;
        display: block;
        width: 300px;
        height: 300px;
        object-fit: contain;
        transition: .5s ease;
        backface-visibility: hidden;
      }
      
      .middle {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 500px;
        height: 500px;
        text-align: center;
      }
      
      .heroSection-cont-text{
        font-size: 16px;
        font-weight: normal;
      }
      
      /* .HeroSection-img{
        position: relative;
        height: 40%;
        width: 80%;
      } */
  }

