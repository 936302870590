.workFlow{
    height: 100%;
    width: 100%;
    padding-top:5%;

}
/* .workflow-header{
    position: relative;
    text-align: right;
    right:70px ; 
    top: 40px;
    
} */

.workflow-img{
    position: relative;
    height: 80vh;
    width: 100%;
    object-fit: contain;
}

.workflow-content-div{
    width: 350px;
}

.workflow-content-div-alter{
    width: 370px;
}

.workflow-content-text{
    text-align: justify;
    font-size: 16px;
    padding: 10px; 
    word-spacing:-2px
    
          
}



.workflow-content-header{
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    background: -webkit-linear-gradient(45deg,#6d2e8e, 
    #6d2e8e );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 5;
}

.workflow-content-cont{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
     bottom:100px
}

@media screen and (max-width:1024px)
{
    .workFlow{
         position: relative;
        height: 100%;
        width: 100%;
        padding-top:0;
        margin-top: 0;
      
    } 
    .workflow-img{
        position: relative;
        height: 800px;
        width: 100%;
        object-fit: contain;
    }
    /* .workflow-header{
        position: relative;
        text-align: right;
        right:70px ; 
        top: 120px;
        
    } */
    
    /* .container workflow-content-cont{
        position: relative;
        top: 40px;
    } */
}
@media screen and (max-width:768px)
{
    .workFlow{
        height: 100%;
        width: 100%;
        padding-top:5%; 
    }
    .workflow-header{
        text-align: center; 
        margin-top: 50px;
    }
    .form-text1{
        font-size: 12px;
        color: black;
        font-weight: 300;
    }
    .workflow-img{
        position: relative;
        height: 300px;
        width: 100%;
        object-fit: contain;      
    
    }
    .workflow-content-div{
        width: 100%;
    }
    .workflow-content-div-alter{
        margin-top: 15px;
        width: 100%;
    }
    .workflow-content-cont{
        display: flex;
        flex-direction: column;
        justify-content:center;
        position: relative;
        bottom:100px
    }
    .workflow-content-text{
        text-align: justify;
    }
    .workflow-content-div{
        margin-top: 20px;
    }

    /* .workflow-header{
        position: relative;
        text-align: right;
        right:50px ; 
        top: 30px;
        
    } */
}