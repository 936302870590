.exploration-cont{
    height: 100%;
    padding-top: 50px;
} 
.Exploration-img-cont{
  position: relative;
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    grid-template-areas: inherit;
    

}

.Exploration-img{
  opacity: 1;
  display: block;
  width: 300px;
  height: 250px;
  object-fit: contain;
  transition: .5s ease;
  backface-visibility: hidden;
}

.Exploration-img-cont1{

  display: grid;
  grid-template-columns: auto;
  justify-content: center;


}
  
.heroimgcontdiv-expo {
    position: relative;
    width:300px;
  }
  
 /* hover */

 .container-expo {
    position: relative;
    /* width: 50%; */
  }
  

  
  .middle-expo {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 250px;
    text-align: center;
  }
  
  /* .container-expo:hover .Exploration-img {
    opacity: 0.3;
  }
  
  .container-expo:hover .middle-expo {
    opacity: 1;
  }

  .text-expo {
    color: rgb(0, 0, 0);
    
    font-size:20px;
    text-align: center;
    margin-top: 35%;
    
  } */




@media (min-width: 640px) {     /* ipad  */
  .exploration-cont{
    height: 100%;
    padding-top: 50px;
} 
    .Exploration-img-cont{
        display: grid;       
        grid-template-columns: auto auto ;
        justify-content: space-around;
       
    }

    .Exploration-img-cont1{

      display: grid;
      grid-template-columns: auto auto   ;
      justify-content: space-around;
    
    
    }
  }

  
  @media (min-width:1280px) {   /* desktop */
    .exploration-cont{
        height: 100vh;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       padding-top: 8%;
       padding-left: 50px;
       padding-right: 50px;
       
    }
    .Exploration-img-cont{

      display: grid;
      grid-template-columns: auto auto auto auto;
      justify-content: center;
      grid-gap: 0px 30px;
    }
    .Exploration-img-cont1{

      position: relative;
      bottom: 30px;
      display: grid;
      grid-template-columns: auto auto auto auto;
      justify-content: center;
      grid-gap: 0px 30px;
  
    }
    .Exploration-img{
      opacity: 1;
      display: block;
      width: 100%;
      height: 250px;
      object-fit: contain;
      transition: .5s ease;
      backface-visibility: hidden;

  }
  
      .middle-expo {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;
      height: 250px;
      text-align: center;
    } 
    /* .text-expo {
      color: rgb(0, 0, 0);
      
      font-size:20px;
      text-align: center;
      margin-top: 35%;
      right: 10px;
    } */

  }



